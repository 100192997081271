import {Store} from '@ngrx/store';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {AppState} from 'app/app.state';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {distinctUntilChanged, first, map, takeUntil} from 'rxjs/operators';
import Tribute from 'tributejs';
import {ContentCacheService} from '../content-cache/content-cache.service';
import {ContentCache} from '../content-cache/content-cache';


interface IProcessParticipantDto {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
}

interface IMentionedContact {
  key: string;
  value: string;
  firstName: string;
  lastName: string;
  isMember: boolean;
  hasAccount: boolean;
}

export class FroalaRepository {
  private onDestroy = new Subject();
  public tribute: Observable<any>;
  private processId$ = new BehaviorSubject<string>(null);
  private returnedCachedValue$ = new BehaviorSubject<string>(null);
  public returnedCachedValue = this.returnedCachedValue$.asObservable();
  private froalaId$ = new BehaviorSubject<string>(null);

  constructor(private _store: Store<AppState>, private contentCacheSvc: ContentCacheService) {
    this._initRepository();
  }

  public destroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.processId$.complete();
    this.froalaId$.complete();
  }

  public set processId(pid) {
    this.processId$.next(pid);
  }

  public set froalaId(id) {
    this.froalaId$.next(id);
  }

  private _initRepository() {
    // TODO : commented for later mention feature
    // const processParticipants$ = this.processId$.pipe(distinctUntilChanged(), takeUntil(this.onDestroy), switchMap(id => {
    //   return this._store.select(ProcessParticipantSelectors.getParticipantsByProcessId(id));
    // }));
    // const emailToName$ = this._store.select(EmailToNameSelectors.getEmailNameMap)
    // const refreshNameByNameStore$ = this.refreshNameByNameStore$(processParticipants$, emailToName$);
    // const membersContactMap$ = this._store.select(ContactSelectors.getMembersOfSelectedOrgMap);
    // const tributeParticipants$ = this.mapParticipantTribute(refreshNameByNameStore$, membersContactMap$);
    // this.tribute = this.manageTribute(tributeParticipants$);
  }

  private manageTribute(tributeParticipants) {
    const self = this;
    return tributeParticipants.pipe(takeUntil(this.onDestroy), distinctUntilChanged(), map(
      (participantsTribute: IMentionedContact[]) => {
        if (participantsTribute && participantsTribute.length > 0) {
          return new Tribute({
            values: participantsTribute,
            menuItemTemplate: function (item) {
              return self._tributeMenuTemplate(item);
            },
            selectTemplate: function (item) {
              if (item && item.original) {
                return self._tributeSelectTemplate(item);
              }
            },
            allowSpaces: true,
            noMatchTemplate() {
              return null
            }
          })
        } else {
          return null;
        }
      }))
  }

  private refreshNameByNameStore$(processParticipants: Observable<ProcessParticipant[]>, EmailToName: Observable<any>): Observable<IProcessParticipantDto[]> {
    return combineLatest(processParticipants, EmailToName).pipe(takeUntil(this.onDestroy), distinctUntilChanged(), map(([participants, emailToNameMap]) => {
      if (!participants || !participants.length) return [];
      return participants.map(p => {
        const emailToNameMatch = emailToNameMap[p.email];
        const processParticipant: IProcessParticipantDto = {
          email: p.email,
          firstName: emailToNameMatch ? emailToNameMatch.firstName : p.firstName,
          lastName: emailToNameMatch ? emailToNameMatch.lastName : p.lastName,
          name: emailToNameMatch ? emailToNameMatch.name : p.name
        };
        return processParticipant;
      })
    }));
  }

  private mapParticipantTribute(refreshNameByNameStore: Observable<IProcessParticipantDto[]>, members: Observable<any>): Observable<IMentionedContact[]> {
    return combineLatest(refreshNameByNameStore, members).pipe(takeUntil(this.onDestroy), distinctUntilChanged(), map(([participants, members]) => {
      if (!participants || !participants.length) return [];
      return participants.map(p => {
        const membersMatch = members[p.email];
        const mentionedContact: IMentionedContact = {
          value: p.email,
          key: membersMatch ? membersMatch.name : p.name,
          firstName: membersMatch ? membersMatch.firstName : p.firstName,
          lastName: membersMatch ? membersMatch.lastName : p.lastName,
          isMember: membersMatch ? membersMatch.isMember : false,
          hasAccount: membersMatch ? membersMatch.hasAccount : false
        }
        return mentionedContact;
      })
    }));
  }

  public _tributeMenuTemplate(item) {
    if (item && item.original) {
      const hasName = item.original.firstName && item.original.lastName;
      if (item.original.isMember) {
        return this._isMemberTemplate(item, hasName);
      } else if (item.original.hasAccount && !item.original.isMember) {
        return this._hasAccountTemplate(item, hasName);
      } else {
        return this._notMemberTemplate(item, hasName);
      }
    }
  }

  private _isMemberTemplate(item, hasName) {
    return `<div class="d-inline-block"
    data-mention-avatar="${hasName ? item.original.firstName[0].toUpperCase() : item.original.value[0].toUpperCase()}${hasName ? item.original.lastName[0].toUpperCase() : item.original.value[0].toUpperCase()}"></div>
    &nbsp;&nbsp;${item.original.key}
     <div class="mention-check-icon mention-verification member-check">check_circle</div>`;
  }

  private _hasAccountTemplate(item, hasName) {
    return `<div class="d-inline-block"
    data-mention-avatar="${hasName ? item.original.firstName[0].toUpperCase() : item.original.value[0].toUpperCase()}${hasName ? item.original.lastName[0].toUpperCase() : item.original.value[0].toUpperCase()}"></div>
    &nbsp;&nbsp;${item.original.key}
     <div class="mention-check-icon mention-verification check">check_circle</div>`;
  }

  private _notMemberTemplate(item, hasName) {
    return `<div class="d-inline-block"
    data-mention-avatar="${hasName ? item.original.firstName[0].toUpperCase() : item.original.value[0].toUpperCase()}${hasName ? item.original.lastName[0].toUpperCase() : item.original.value[0].toUpperCase()}"></div>
    &nbsp;&nbsp;${item.original.key}`;
  }

  public _tributeSelectTemplate(item) {
    return `<span class="fr-deletable fr-tribute" data-mentioned-email="${item.original.value}" contenteditable="false">${item.original.key}</a></span>`;
  }

  // add , remove, and load Cache Froala text Section
  public cacheContent(textValue) {
    if (this.froalaId$.value) {
      const contentCache = new ContentCache(this.froalaId$.value, textValue);
      this.contentCacheSvc.updateContentCache(contentCache)
        .pipe(first())
        .subscribe();
    }
  }


  public initContentCache() {
    if (this.froalaId$.value) {
      this.contentCacheSvc.getContentCacheById(this.froalaId$.value)
        .pipe(first())
        .subscribe(value => {
          if (value && value.content) {
            this.returnedCachedValue$.next(value.content);
          }
        });
    }
  }

  public RemoveContentCache() {
    if (this.froalaId$.value)
      this.contentCacheSvc.removeContentCache(this.froalaId$.value)
        .pipe(first())
        .subscribe();
  }
}
