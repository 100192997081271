import {CollectionContext, CollectionController, ICollectionStore} from '../collection/collection';
import {DmsProvider, ProcessArtifact} from './process-artifact';
import {Observable} from 'rxjs/internal/Observable';
import {AppState} from '../../app.state';
import {Store} from '@ngrx/store';
import {ProcessArtifactActions, ProcessArtifactSelectors} from './index';
import {getCollectionLoadingState} from './process-artifact.selectors';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {filter, map, switchMap} from 'rxjs/operators';

export class ProcessArtifactCollectionContext extends CollectionContext<ProcessArtifact> {
  searchTerm: string = null;
  externalAccessId: string = null;
  dmsProvider: DmsProvider = null;

  /**
   * Adds a prefix to the collection ID to not override other used contexts.
   */
  contextSandbox: string = null;

  constructor(public processId: string,
              public referenceId: string = null,
              public role: string = null) {
    super();
  }

  getCollectionId(): string {
    const prefix = this.contextSandbox ? this.contextSandbox + '|' : '';
    return prefix + ProcessArtifact.getCollectionContextId(this.processId, this.referenceId, this.role, this.dmsProvider);
  }

  /**
   * Clone implements a deep copy of the context for immutable ID access.
   */
  public clone(): ProcessArtifactCollectionContext {
    const copy = new ProcessArtifactCollectionContext(this.processId, this.referenceId, this.role);
    copy.contextSandbox = this.contextSandbox;
    copy.searchTerm = this.searchTerm;
    copy.externalAccessId = this.externalAccessId;
    copy.dmsProvider = this.dmsProvider;
    return copy;
  }
}

export class ProcessArtifactCollection extends CollectionController<ProcessArtifact> {
  public hasNext: Observable<boolean>;

  private context: ProcessArtifactCollectionContext;
  private contextId$ = new BehaviorSubject<string>(null);

  constructor(private store: Store<AppState>, args = null) {
    super();

    this.context = new ProcessArtifactCollectionContext(args?.processId, args?.referenceId, args?.role);
    const contextId = this.contextId$.pipe(filter(cid => !!cid));

    this.data = contextId.pipe(switchMap(cid => store.select(ProcessArtifactSelectors.getCollectionByContext(cid))));
    this.loading = contextId.pipe(switchMap(cid => store.select(ProcessArtifactSelectors.getCollectionLoadingState(cid))));
    this.size = contextId.pipe(switchMap(cid => store.select(ProcessArtifactSelectors.getCollectionSize(cid))));
    this.total = contextId.pipe(switchMap(cid => store.select(ProcessArtifactSelectors.getCollectionTotal(cid))));
    this.hasNext = contextId.pipe(switchMap(cid => store.select(ProcessArtifactSelectors.getCollectionHasNext(cid))));
  }

  public static removeFromCollections(collections, artifactId: string): {[id: string]: ICollectionStore} {
    if (!artifactId) {
      return collections;
    }

    const newCollections: {[id: string]: ICollectionStore} = Object.assign({}, collections);
    for (const id in newCollections) {
      if (newCollections.hasOwnProperty(id)) {
        const collection = newCollections[id];
        collection.ids = collection.ids.filter(aid => aid !== artifactId);
        collection.size -= 1;
      }
    }
    return newCollections;
  }

  public static updateAllCollections(collections, artifact: ProcessArtifact): {[id: string]: ICollectionStore}  {
    if (!artifact) {
      return collections;
    }

    const newCollections: {[id: string]: ICollectionStore} = Object.assign({}, collections);
    for (const id in newCollections) {
      if (newCollections.hasOwnProperty(id) && artifact.hasValidContext(id)) {
        const collection = newCollections[id];
        if (collection.ids.includes(artifact.id)) {
          continue;
        }

        collection.ids = [artifact.id, ...collection.ids];
        collection.size += 1;

      } else if (newCollections.hasOwnProperty(id)) {
        const collection = newCollections[id];
        if (collection.ids.includes(artifact.id)) {
          collection.ids = collection.ids.filter(aid => aid !== artifact.id);
        }
      }
    }
    return newCollections;
  }

  public search(term: string) {
    if (this.context.searchTerm === term) {
      return;
    }

    this.context = this.context.clone();
    this.context.searchTerm = term;
    this.commit(true);
  }

  public loadNext() {
    this.store.dispatch(new ProcessArtifactActions.LoadNext(this.context));
  }

  static create(store: Store<AppState>, keys) {
    return new ProcessArtifactCollection(store, keys);
  }

  /**
   * Initializes the collection and triggers the initial API call with query parameters.
   * Should be invoked after all parameter are set.
   *
   * @param reset
   */
  public commit(reset: boolean = false) {
    this.context = this.context.clone();
    this.contextId$.next(this.context.getCollectionId());
    this.store.dispatch(new ProcessArtifactActions.InitOrResetCollection(this.context, reset));
  }

  set processId(processId: string) {
    this.context.processId = processId;
    this.contextId$.next(this.context.getCollectionId());
  }

  set referenceId(rid: string) {
    this.context = this.context.clone();
    this.context.referenceId = rid;
    this.contextId$.next(this.context.getCollectionId());
  }

  /**
   * Sets an external link filter.
   *
   * @param eaId
   */
  set externalAccessId(eaId: string) {
    this.context = this.context.clone();
    this.context.externalAccessId = eaId;
    this.contextId$.next(this.context.getCollectionId());
  }

  /**
   * Sets the DMS provider for sync contexts.
   *
   * @param provider
   */
  set dmsProvider(provider: DmsProvider) {
    this.context = this.context.clone();
    this.context.dmsProvider = provider;
    this.contextId$.next(this.context.getCollectionId());
  }

  /**
   * Sets a sandbox for the collection to not interfere with other collections.
   * Sandboxes are not updated on parallel uploads.
   *
   * @param contextSandbox
   */
  set contextSandbox(contextSandbox: string) {
    this.context = this.context.clone();
    this.context.contextSandbox = contextSandbox;
    this.contextId$.next(this.context.getCollectionId());
  }

  /**
   * Cleanup of unsused collection.
   */
  discard() {
    this.contextId$.complete();
  }
}
