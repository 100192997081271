import {IResource} from 'app/lib/fivef-net/fivef-api-resource/models/resource.interface';
import {DmsProvider} from './process-artifact';

/**
 * Supported attributes of the select query on the artifact index v3 API.
 * See IProcessArtifactQueryParams.
 */
export type SUPPORTED_SELECT_ATTRS = 'id' | 'title' | 'role' | 'new_upload' | 'reference_id' | 'size' | 'performer' | 'created_at';

/**
 * Default selection of attributes for artifact stat calls.
 */
export const DEFAULT_ARTIFACT_SELECT_ATTRS: SUPPORTED_SELECT_ATTRS[] = ['id', 'new_upload', 'reference_id', 'role'];

/**
 * Filters for the artifact stats call.
 * Without filters all artifacts are fetched with process ID.
 */
export interface IProcessArtifactStatsFilters {
  /**
   * Restricts stat fetching to artifacts out of IDs.
   */
  ids?: string[];

  /**
   * Restricts stat fetching to artifacts with role.
   */
  role?: 'default' | 'template',

  /**
   * Restricts stat fetching to artifacts with referenced ID.
   */
  referenceId?: string;

  /**
   * Restricts stat fetching to new / unread artifacts.
   */
  newUploadsOnly?: boolean;
}

/**
 * Query parameters supported by the artifact v3 API.
 */
export interface IProcessArtifactQueryParams {
  /**
   * Reference ID of artifact at Collecto, 3rd Party or CAC.
   */
  referenceId?: string;

  /**
   * Search string matching filename, year, month and type.
   */
  searchTerm?: string;

  /**
   * Role of artifact: template or default.
   * Optional.
   */
  role?: string;

  /**
   * External access ID scope.
   */
  eaId?: string;

  /**
   * DMS provider for sync candidate scopes.
   */
  dmsProvider?: DmsProvider;

  /**
   * Attribute selection of minimal 'all items' API mode.
   * Supported values are:
   * - id
   * - reference_id
   * - role
   * - new_upload
   */
  select?: SUPPORTED_SELECT_ATTRS[];

  /**
   * Returns only new uploads.
   */
  newUploadsOnly?: boolean;

  /**
   * Returns a restricted result set out of IDs.
   */
  ids?: string[];
}

export interface IDocumentExportedMarker {
  organizationId: string;
  performer: string;
  createdAt: Date;
}

export interface IProcessArtifact extends IResource {
  processId: string;
  title: string;
  name: string;
  description: string;
  revision: string;
  dmsDocumentId: string;
  referenceId: string;
  role: string;
  size: number;
  createdAt: Date;
  updatedAt: Date;
  url: string;
  dmsRoute: string;
  ownerName: string;
  uploaderName: string;
  uploaderEmail?: string;
  comment: string;
  publicAvailable: boolean;
  exports: IDocumentExportedMarker[];
  bookmanExportedAt?: string;
  newUpload?: boolean;
}
